import React from "react";
import styles from "./Pipeline.module.scss";
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from "../../assets/icons/apply-samurai-icon.png";
import TuneIcon from '@mui/icons-material/Tune';
import ConstructionIcon from '@mui/icons-material/Construction';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import axios from "axios";
import {
	Routes,
	Route,
	useNavigate,
} from "react-router-dom";
import {
	API_URL,
} from "../../constants";
import {
	ServiceSelect,
	Chatbot,
} from "../../components";
import Connections from "../Connections";
import FurtherContext from "../FurtherContext";
import Generate from "../Generate";
import JobPosting from "../JobPosting";
import LinkedInProfile from "../LinkedInProfile";
import RecipientInformation from "../RecipientInformation";
import ResumeUpload from "../ResumeUpload";
import UserBio from "../UserBio";
import CompanyInformation from "../CompanyInformation";
import Questions from "../Questions";
import Error from "../Error";
import Sensei from "../Sensei";
import { ReactComponent as ChatPasteGoIcon } from "../../assets/icons/chat_paste_go.svg"
import { ReactComponent as HistoryEduIcon } from "../../assets/icons/history_edu.svg"
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/question_mark.svg"
import { ReactComponent as QuickReplyIcon } from "../../assets/icons/quickreply.svg"

const SERVICES = {
	coverLetter: {
		title: "Cover Letter", name: "CoverLetter", endpoint: "cover_letter", href: "/cover-letter", icon: <HistoryEduIcon />, download: true, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: true, recommended: true, title: "Please upload your resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			JOB_POSITION_POSTING: {label: "Job Posting", icon: "text", optional: false, recommended: null, title: "Please copy and paste the job posting here.", stepKey: "JOB_POSITION_POSTING", href: "/job-posting", helperText: ""},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			CONNECTIONS: {label: "Connections", icon: "text", optional: true, recommended: false, title: "Do you have any connections with the recipient? If so, who or what, and to what extent?", stepKey: "CONNECTIONS", href: "/connections", helperText: "Feel free to be as detailed as you would like, and to include any information that you think might be relevant, such as mutual connections, shared experiences, or anything else that might help us personalize the message."},
			output: {label: "Generate Cover Letter", icon: "generate", optional: false, recommended: null, title: "Cover Letter", descriptiveText: false, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	followUp: {
		title: "Follow Up Message", name: "FollowUp", endpoint: "follow_up", href: "/follow-up", icon: <QuickReplyIcon />, download: false, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: true, recommended: true, title: "Please upload your resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			JOB_POSITION_POSTING: {label: "Job Posting", icon: "text", optional: true, recommended: true, title: "Please copy and paste the job posting here.", stepKey: "JOB_POSITION_POSTING", href: "/job-posting", helperText: ""},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			MESSAGE_RECIPIENT_INFORMATION: {label: "Recipient Information", icon: "text", optional: false, recommended: null, title: "Please tell us a little bit about who this message is for.", stepKey: "MESSAGE_RECIPIENT_INFORMATION", href: "/recipient-info", helperText: ""},
			CONNECTIONS: {label: "Connections", icon: "text", optional: true, recommended: false, title: "Do you have any connections with the recipient? If so, who or what, and to what extent?", stepKey: "CONNECTIONS", href: "/connections", helperText: "Feel free to be as detailed as you would like, and to include any information that you think might be relevant, such as mutual connections, shared experiences, or anything else that might help us personalize the message."},
			FURTHER_CONTEXT: {label: "Additional Details", icon: "text", optional: true, recommended: false, title: "Is there anything else that you think we should know before generating the message?", stepKey: "FURTHER_CONTEXT", href: "/additional-details", helperText: ""},
			output: {label: "Generate Follow Up Message", icon: "generate", optional: false, recommended: null, title: "Follow Up Message", descriptiveText: false, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	reachOut: {
		title: "Reach Out Message", name: "ReachOut", endpoint: "reach_out", href: "/reach-out", icon: <ChatPasteGoIcon />, download: false, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: true, recommended: true, title: "Please upload your resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			COMPANY_INFORMATION: {label: "Company Information", icon: "text", optional: true, recommended: false, title: "Please tell us a little bit about the company, if any, that you are reaching out in regards to.", stepKey: "COMPANY_INFORMATION", href: "/company-info", helperText: ""},
			MESSAGE_RECIPIENT_INFORMATION: {label: "Recipient Information", icon: "text", optional: false, recommended: null, title: "Please tell us a little bit about who this message is for.", stepKey: "MESSAGE_RECIPIENT_INFORMATION", href: "/recipient-info", helperText: ""},
			CONNECTIONS: {label: "Connections", icon: "text", optional: true, recommended: false, title: "Do you have any connections with the recipient? If so, who or what, and to what extent?", stepKey: "CONNECTIONS", href: "/connections", helperText: "Feel free to be as detailed as you would like, and to include any information that you think might be relevant, such as mutual connections, shared experiences, or anything else that might help us personalize the message."},
			FURTHER_CONTEXT: {label: "Additional Details", icon: "text", optional: true, recommended: false, title: "Is there anything else that you think we should know before generating the message?", stepKey: "FURTHER_CONTEXT", href: "/additional-details", helperText: ""},
			output: {label: "Generate Reach Out Message", icon: "generate", optional: false, recommended: null, title: "Reach Out Message", descriptiveText: false, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	questions: {
		title: "Answer An Application Question", name: "Questions", endpoint: "questions", href: "/questions", icon: <QuestionMarkIcon />, download: false, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: true, recommended: true, title: "Please upload your resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			JOB_POSITION_POSTING: {label: "Job Posting", icon: "text", optional: true, recommended: true, title: "Please copy and paste the job posting here.", stepKey: "JOB_POSITION_POSTING", href: "/job-posting", helperText: ""},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			QUESTIONS: {label: "Question", icon: "text", optional: false, recommended: null, title: "Please copy and paste the application question here.", stepKey: "QUESTIONS", href: "/question", helperText: ""},
			FURTHER_CONTEXT: {label: "Additional Details", icon: "text", optional: true, recommended: false, title: "Is there anything else that you think we should know before generating the answer?", stepKey: "FURTHER_CONTEXT", href: "/additional-details", helperText: ""},
			output: {label: "Generate Answer", icon: "generate", optional: false, recommended: null, title: "Answer", descriptiveText: false, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	buildResume: {
		title: "Build A Resume From Scratch", name: "BuildResume", endpoint: "build_resume", href: "/build-resume", icon: <ConstructionIcon sx={{ fontSize: 40 }} />, download: true, description: "",
		steps: {
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			output: {label: "Generate New Resume", icon: "generate", optional: false, recommended: null, title: "New Resume", descriptiveText: true, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	improveResume: {
		title: "Improve An Existing Resume", name: "ImproveResume", endpoint: "improve_resume", href: "/improve-resume", icon: <KeyboardDoubleArrowUpIcon sx={{ fontSize: 40 }} />, download: true, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: false, recommended: null, title: "Please upload your current resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			output: {label: "Generate Improved Resume", icon: "generate", optional: true, recommended: false, title: "Improved Resume", descriptiveText: true, stepKey: "output", href: "/output", helperText: ""},
		},
	},
	tuneResume: {
		title: "Tune A Resume For A Specific Job", name: "TuneResume", endpoint: "tune_resume", href: "/tune-resume", icon: <TuneIcon sx={{ fontSize: 40 }} />, download: true, description: "",
		steps: {
			USER_RESUME: {label: "Resume", icon: "upload", optional: false, recommended: null, title: "Please upload your current resume", stepKey: "USER_RESUME", href: "/resume", helperText: ""},
			USER_LINKEDIN_PROFILE: {label: "LinkedIn", icon: "text", optional: true, recommended: true, title: "Please copy and paste your LinkedIn profile here", stepKey: "USER_LINKEDIN_PROFILE", href: "/linkedin-profile", helperText: "Unfortunately, we can't access LinkedIn profiles directly at the moment. However, you can copy and paste everything, and we'll figure it out. Be sure to expand any hidden text, and if clicking on a show all or see more opens another page, just copy and paste it below the previous pages."},
			JOB_POSITION_POSTING: {label: "Job Posting", icon: "text", optional: false, recommended: null, title: "Please copy and paste the job posting here.", stepKey: "JOB_POSITION_POSTING", href: "/job-posting", helperText: ""},
			USER_INFORMATION: {label: "Professional Bio", icon: "text", optional: true, recommended: false, title: "Tell us a little bit about yourself", stepKey: "USER_INFORMATION", href: "/bio", helperText: ""},
			output: {label: "Generate Tuned Resume", icon: "generate", optional: true, recommended: false, title: "Tuned Resume", descriptiveText: true, stepKey: "output", href: "/output", helperText: ""},
		},
	},
}

function Pipeline(props) {
  const isMobile = useMediaQuery('(max-width: 767px)');
	const navigate = useNavigate();

	const handleServiceSelect = (newService, path) => {
		const serviceSteps = SERVICES[newService].steps;
		const firstStep = Object.values(serviceSteps)[0];
		props.setService(newService);
		props.setSteps(serviceSteps);
		props.setActiveStep(firstStep);
		props.setDisableNext(!firstStep.optional);
		props.setActiveStepIndex(0);
		navigate(path);
	}

	const changeStep = (newStepIndex) => {
		props.setActiveStepIndex(newStepIndex);
		const newStep = Object.values(props.steps)[newStepIndex]
		if (newStepIndex === -1) {
			props.resetState();
			navigate("/");
		} else {
			if (newStep?.stepKey === "output") {
				handleGenerate();
			}
			props.setActiveStep(newStep);
			props.setDisableNext(!newStep?.optional && !props.completedSteps.includes(newStep?.stepKey) ? true : false);
			navigate(`${SERVICES?.[props.service]?.href}${newStep.href}`);
		}
	}

	const handleGenerate = async () => {
		props.setLoading(true);
		await Promise.all(props.processUploadCalls);
		axios.post(`${API_URL}/${SERVICES[props.service].endpoint}/lite`, props.data)
		.then((res) => {
			props.setLoading(false);
			props.setOutput(res.data.output);
			props.setPlaceholdersUsed(res.data?.placeholdersUsed || false);
			props.setUserName(res?.data?.userName || "");
			props.setProfessionalTitle(res?.data?.professionalTitle || "");
		})
		.catch((error) => {
			props.setLoading(false);
			console.log(`${props.service} - handleGenerate - ERROR`, error)
		});
	}

	const processUpload = (key) => {
		const processData = key === "USER_RESUME" ? props.data?.[key]?.[0] || "" : props.data?.[key];
		if (processData) {
			props.setLoading(true);
			const formData = new FormData();
			formData.append(key, processData);
			Object.entries(props.data).forEach(([key, value]) => {
				formData.append(key, value);
			});
			axios.post(`${API_URL}/${key.toLowerCase()}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => {
				props.setLoading(false);
				if (key === "JOB_POSITION_POSTING") {
					props.setCompany(res.data.company);
					props.setJobTitle(res.data.jobTitle);
				} else {
					handleUpdateData(key, res.data.processed);
				}
				changeStep(props.activeStepIndex + 1);
				props.setCompletedSteps([
					...props.completedSteps,
					props.activeStep.stepKey,
				])
			})
			.catch((error) => {
				console.log(`${key} - processUpload - ERROR`, error);
				props.setLoading(false);
				changeStep(props.activeStepIndex + 1);
				props.setCompletedSteps([
					...props.completedSteps,
					props.activeStep.stepKey,
				])
			});
		} else {
			if (key === "JOB_POSITION_POSTING") {
				props.setCompany("");
				props.setJobTitle("");
			} else {
				handleUpdateData(key, "");
			}
			changeStep(props.activeStepIndex + 1);
			props.setCompletedSteps([
				...props.completedSteps,
				props.activeStep.stepKey,
			])
		}
	}

	const processUploadAsync = (key) => {
		const processData = key === "USER_RESUME" ? props.data?.[key]?.[0] || "" : props.data?.[key];
		if (processData) {
			props.setLoading(true);
			const formData = new FormData();
			formData.append(key, processData);
			Object.entries(props.data).forEach(([key, value]) => {
				formData.append(key, value);
			});
			const apiCall = axios.post(`${API_URL}/${key.toLowerCase()}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => {
				if (key === "JOB_POSITION_POSTING") {
					props.setCompany(res.data.company);
					props.setJobTitle(res.data.jobTitle);
				}
				handleUpdateData(key, res.data.processed);
			})
			.catch((error) => {
				console.log(`${key} - processUploadAsync - ERROR`, error);
			})
			.finally(() => {
				props.setProcessUploadCalls(m => m.filter(p => p !== apiCall));
			});
			
			props.setProcessUploadCalls(m => [...m, apiCall]);
			props.setLoading(false);
		} else {
			handleUpdateData(key, "");
		}
	}

	const nextStep = () => {
		if (props.activeStep.stepKey === "USER_RESUME") {
			processUpload("USER_RESUME");
		} else if (props.activeStep.stepKey === "USER_LINKEDIN_PROFILE") {
			processUpload("USER_LINKEDIN_PROFILE");
		} else if (props.activeStep.stepKey === "JOB_POSITION_POSTING") {
			processUpload("JOB_POSITION_POSTING");
		} else {
			changeStep(props.activeStepIndex + 1);
			props.setCompletedSteps([
				...props.completedSteps,
				props.activeStep.stepKey,
			])
		}
	}

	const nextStepAsync = () => {
		if (props.activeStep.stepKey === "USER_RESUME") {
			processUploadAsync("USER_RESUME");
		} else if (props.activeStep.stepKey === "USER_LINKEDIN_PROFILE") {
			processUploadAsync("USER_LINKEDIN_PROFILE");
		} else if (props.activeStep.stepKey === "JOB_POSITION_POSTING") {
			processUploadAsync("JOB_POSITION_POSTING");
		}
		changeStep(props.activeStepIndex + 1);
		props.setCompletedSteps([
			...props.completedSteps,
			props.activeStep.stepKey,
		])
	}

	const previousStep = () => {
		changeStep(props.activeStepIndex - 1);
	}

	const handleChange = (key, value) => {
		handleUpdateData(key, value);
	}

	const handleUpdateData = (key, value) => {
		if (key === "output") {
			props.setOutput(value);
		} else {
			if (key === "USER_RESUME") {
				props.setUserResume(value);
			} else if (key === "USER_LINKEDIN_PROFILE") {
				props.setUserLinkedInProfile(value);
			} else if (key === "USER_INFORMATION") {
				props.setUserInformation(value);
			} else if (key === "JOB_POSITION_POSTING") {
				props.setJobPositionPosting(value);
			} else if (key === "MESSAGE_RECIPIENT_INFORMATION") {
				props.setMessageRecipientInformation(value);
			} else if (key === "QUESTIONS") {
				props.setQuestions(value);
			} else if (key === "CONNECTIONS") {
				props.setConnections(value);
			} else if (key === "FURTHER_CONTEXT") {
				props.setFurtherContext(value);
			}
			props.setData(m => ({
				...m,
				[key]: value,
			}));
			if (!props.activeStep.optional && value !== "") {
				props.setDisableNext(false);
			}
		}
	}

	return (
		<div className={styles.pipeline}>
			{/* <Chatbot /> */}
			<AppBar position="static" sx={{ marginBottom: "15px" }}>
				<Toolbar>
					<div className={styles.logo} onClick={() => changeStep(-1)}>
						<img className={styles.logoImg} src={Logo} alt="logo" />
					</div>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						Apply SamurAI
					</Typography>
				</Toolbar>
			</AppBar>
			<div className={styles.body}>
				<Routes>
					<Route path="/" element={<ServiceSelect onClick={handleServiceSelect} services={SERVICES} navigate={navigate} />} />
					<Route path="/sensei" element={<Sensei />} />
					<Route path="/cover-letter">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/cover-letter/resume" onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/cover-letter/linkedin-profile" onChange={handleChange} data={props.userLinkedInProfile} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="job-posting" element={<JobPosting stepKey="JOB_POSITION_POSTING" href="/cover-letter/job-posting" onChange={handleChange} data={props.jobPositionPosting} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/cover-letter/bio" onChange={handleChange} data={props.userInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="connections" element={<Connections stepKey="CONNECTIONS" href="/cover-letter/connections" onChange={handleChange} data={props.connections} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/cover-letter/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={props.setOutput} regenerate={handleGenerate} output={props.output} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/follow-up">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/follow-up/resume" onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/follow-up/linkedin-profile" onChange={handleChange} data={props.userLinkedInProfile} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="job-posting" element={<JobPosting stepKey="JOB_POSITION_POSTING" href="/follow-up/job-posting" onChange={handleChange} data={props.jobPositionPosting} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/follow-up/bio" onChange={handleChange} data={props.userInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="recipient-info" element={<RecipientInformation stepKey="MESSAGE_RECIPIENT_INFORMATION" href="/follow-up/recipient-information" onChange={handleChange} data={props.messageRecipientInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="connections" element={<Connections stepKey="CONNECTIONS" href="/follow-up/connections" onChange={handleChange} data={props.connections} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="additional-details" element={<FurtherContext stepKey="FURTHER_CONTEXT" href="/follow-up/additional-details" onChange={handleChange} data={props.furtherContext} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/follow-up/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={props.setOutput} regenerate={handleGenerate} output={props.output} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/reach-out">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/reach-out/resume" onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/reach-out/linkedin-profile" onChange={handleChange} data={props.userLinkedInProfile} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/reach-out/bio" onChange={handleChange} data={props.userInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="company-info" element={<CompanyInformation stepKey="COMPANY_INFORMATION" href="/reach-out/company-info" onChange={handleChange} data={props.messageRecipientInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="recipient-info" element={<RecipientInformation stepKey="MESSAGE_RECIPIENT_INFORMATION" href="/reach-out/recipient-information" onChange={handleChange} data={props.messageRecipientInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="connections" element={<Connections stepKey="CONNECTIONS" href="/reach-out/connections" onChange={handleChange} data={props.connections} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="additional-details" element={<FurtherContext stepKey="FURTHER_CONTEXT" href="/reach-out/additional-details" onChange={handleChange} data={props.furtherContext} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/reach-out/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={props.setOutput} regenerate={handleGenerate} output={props.output} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/questions">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/questions/resume" onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/questions/linkedin-profile" onChange={handleChange} data={props.userLinkedInProfile} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="job-posting" element={<JobPosting stepKey="JOB_POSITION_POSTING" href="/questions/job-posting" onChange={handleChange} data={props.jobPositionPosting} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/questions/bio" onChange={handleChange} data={props.userInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="question" element={<Questions stepKey="QUESTIONS" href="/questions/question" onChange={handleChange} data={props.questions} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="additional-details" element={<FurtherContext stepKey="FURTHER_CONTEXT" href="/questions/additional-details" onChange={handleChange} data={props.furtherContext} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/questions/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={props.setOutput} regenerate={handleGenerate} output={props.output} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/build-resume">
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/build-resume/linkedin-profile" onChange={handleChange} data={props.userLinkedInProfile} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/build-resume/bio" onChange={handleChange} data={props.userInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/build-resume/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={props.setOutput} regenerate={handleGenerate} output={props.output} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/improve-resume">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/improve-resume/resume" onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/improve-resume/linkedin-profile" onChange={handleChange} data={props.userLinkedInProfile} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/improve-resume/bio" onChange={handleChange} data={props.userInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/improve-resume/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={props.setOutput} regenerate={handleGenerate} output={props.output} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="/tune-resume">
						<Route path="resume" element={<ResumeUpload stepKey="USER_RESUME" href="/tune-resume/resume" onChange={handleChange} onFileChange={handleUpdateData} data={props.userResume} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="linkedin-profile" element={<LinkedInProfile stepKey="USER_LINKEDIN_PROFILE" href="/tune-resume/linkedin-profile" onChange={handleChange} data={props.userLinkedInProfile} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="job-posting" element={<JobPosting stepKey="JOB_POSITION_POSTING" href="/tune-resume/job-posting" onChange={handleChange} data={props.jobPositionPosting} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="bio" element={<UserBio stepKey="USER_INFORMATION" href="/tune-resume/bio" onChange={handleChange} data={props.userInformation} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} loading={props.loading} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} />} />
						<Route path="output" element={<Generate stepKey="output" href="/tune-resume/output" download={SERVICES?.[props.service]?.download} loading={props.loading} onChange={props.setOutput} regenerate={handleGenerate} output={props.output} activeStep={props.activeStep} isMobile={isMobile} disableNext={props.disableNext} previousStep={previousStep} nextStep={nextStepAsync} changeStep={changeStep} activeStepIndex={props.activeStepIndex} steps={props.steps} stepLen={Object.keys(props.steps).length} services={SERVICES} service={props.service} company={props.company} jobTitle={props.jobTitle} userName={props.userName} professionalTitle={props.professionalTitle} />} />
					</Route>
					<Route path="*" element={<Error />} />
				</Routes>
			</div>
		</div>
	)
}

export default Pipeline;