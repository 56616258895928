import React, { useState, useRef, useEffect } from "react";
import styles from "./Chatbot.module.scss";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BadgeIcon from '@mui/icons-material/Badge';
import IconButton from '@mui/material/IconButton';
import sanitize from "../../util/sanitize";
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import axios from "axios";
import {
	API_URL,
} from "../../constants";


const SystemMessage = (props) => {
	return (
		<div className={`${styles.systemMessage} ${styles.message}`}>
			<div className={`${styles.systemMessageBlock} ${styles.messageBlock}`}>
				{props.message}
			</div>
		</div>
	)
};

const UserMessage = (props) => {
	return (
		<div className={`${styles.userMessage} ${styles.message}`}>
			<div className={`${styles.userMessageBlock} ${styles.messageBlock}`}>
				{props.message}
			</div>
		</div>
	)
};

const PopUpTextField = (props) => {
	const [show, setShow] = useState(false);
	const [value, setValue] = useState("");

	const handleInputChange = (e) => {
		setValue(e.target.value);
	}

	const handleClick = (buttonName) => {
		if (props.onClick) {
			props.onClick(buttonName, value);
		}
	};

	const handleIconClick = () => {
		setShow(!show);
	};

	return (
		<div className={styles.popUpTextField}>
			<div className={styles.popUpIcon}>
				<Tooltip title={props.title} arrow>
					<IconButton
						className={styles.sidebarButton}
						onClick={() => handleIconClick()}
					>
						{props.children}
					</IconButton>
				</Tooltip>
			</div>
			{show &&
				<TextField
					className={styles.popUpTextFieldInput}
					type="text"
					sx={{
						'& .MuiInputBase-root': {
							padding: "14px",
						},
						'& .MuiInputBase-input': {
							color: "#FFFFFF",
						},
						'& .MuiInputBase-input::placeholder': {
							color: "#FFFFFF",
						},
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								borderColor: 'transparent',
							},
							'&:hover fieldset': {
								borderColor: 'transparent',
							},
							'&.Mui-focused fieldset': {
								borderColor: 'transparent',
							},
						},
					}}
					placeholder={props.placeholder}
					rows={9}
					onChange={(e) => handleInputChange(e)}
					value={value}
					fullWidth
					multiline
				/>}
		</div>
	)
};

const Sidebar = (props) => {
	const resumeUploadRef = useRef(null);
	const [showLinkedIn, setShowLinkedIn] = useState(false);
	const [showJobPosting, setShowJobPosting] = useState(false);
	const [linkedInValue, setLinkedInValue] = useState("");
	const [jobPostingValue, setJobPostingValue] = useState("");
	const [linkedinLoading, setLinkedInLoading] = useState(false);
	const [resumeLoading, setResumeLoading] = useState(false);
	const [jobPostingLoading, setJobPostingLoading] = useState(false);

	const handleClick = (buttonName) => {
		if (buttonName === "resume") {
			resumeUploadRef.current.click();
		} else if (buttonName === "linkedin") {
			setShowLinkedIn(!showLinkedIn);
		} else if (buttonName === "jobPosting") {
			setShowJobPosting(!showJobPosting);
		}
	};

	const handleUploadResume = (e) => {
		setResumeLoading(true);
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append("USER_RESUME", file);
		axios.post(`${API_URL}/user_resume`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then((res) => {
			props.setUserResume(res.data.processed);
			setResumeLoading(false);
		})
		.catch((err) => {
			console.error('handleUploadResume - ERROR:', err);
			setResumeLoading(false);
		})
	};

	const handleSubmitLinkedIn = () => {
		setShowLinkedIn(false);
		setLinkedInLoading(true);
		const formData = new FormData();
		formData.append("USER_LINKEDIN_PROFILE", linkedInValue);
		axios.post(`${API_URL}/user_resume`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then((res) => {
			props.setUserLinkedIn(res.data.processed);
			setLinkedInLoading(false);
		})
		.catch((err) => {
			console.error('handleSubmitLinkedIn - ERROR:', err);
			setLinkedInLoading(false);
		})
	};

	const handleSubmitJobPosting = () => {
		setShowLinkedIn(false);
		setJobPostingLoading(true);
		const formData = new FormData();
		formData.append("JOB_POSITION_POSTING", jobPostingValue);
		axios.post(`${API_URL}/job_position_posting`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then((res) => {
			props.setJobPosting(res.data.processed);
			setJobPostingLoading(false);
		})
		.catch((err) => {
			console.error('handleSubmitJobPosting - ERROR:', err);
			setJobPostingLoading(false);
		})
	};

	const SX = {
		color: "#FFFFFF",
		padding: "0px",
		margin: "0px",
		height: "48px",
		width: "48px",
	};
	return (
		<div className={styles.sidebar}>
			<div className={styles.sidebarContent}>
				<input type="file" ref={resumeUploadRef} style={{display: "none"}} onChange={(e) => handleUploadResume(e)} />
				<Tooltip title="Upload Resume" arrow>
					<IconButton
						className={styles.sidebarButton}
						onClick={() => handleClick("resume")}
					>
						<UploadFileIcon sx={SX} />
					</IconButton>
				</Tooltip>
				<Tooltip title="LinkedIn" arrow>
					<IconButton
						className={styles.sidebarButton}
						onClick={() => handleClick("linkedin")}
					>
						<LinkedInIcon sx={SX} />
						{showLinkedIn &&
							<ClickAwayListener onClickAway={handleSubmitLinkedIn}>
								<FormControl
									className={styles.popUpFieldFormControl}
									fullWidth
								>
									<div onClick={(e) => e.stopPropagation()}>
										<TextField
											className={styles.popUpTextFieldInput}
											type="text"
											placeholder="Please Copy and Paste Your LinkedIn Profile Here..."
											rows={15}
											onChange={(e) => setLinkedInValue(e)}
											value={linkedInValue}
											fullWidth
											multiline
											sx={{
												'& .MuiInputBase-root': {
													padding: "14px",
												},
												'& .MuiInputBase-input': {
													color: "#FFFFFF",
												},
												'& .MuiInputBase-input::placeholder': {
													color: "#FFFFFF",
												},
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'transparent',
													},
													'&:hover fieldset': {
														borderColor: 'transparent',
													},
													'&.Mui-focused fieldset': {
														borderColor: 'transparent',
													},
												},
											}}
										/>
									</div>
								</FormControl>
							</ClickAwayListener>
							}
					</IconButton>
				</Tooltip>
				<Tooltip title="Job Posting" arrow>
					<IconButton
						className={styles.sidebarButton}
						onClick={() => handleClick("jobPosting")}
					>
						<BadgeIcon sx={SX} />
						{showJobPosting &&
							<ClickAwayListener onClickAway={handleSubmitJobPosting}>
								<FormControl
									className={styles.popUpFieldFormControl}
									fullWidth
								>
									<div onClick={(e) => e.stopPropagation()}>
										<TextField
											className={styles.popUpTextFieldInput}
											type="text"
											placeholder="Please Copy and Paste The Job Posting Here (You may also give this to Sensei in the chat)..."
											rows={15}
											onChange={(e) => setJobPostingValue(e)}
											value={jobPostingValue}
											fullWidth
											multiline
											sx={{
												'& .MuiInputBase-root': {
													padding: "14px",
												},
												'& .MuiInputBase-input': {
													color: "#FFFFFF",
												},
												'& .MuiInputBase-input::placeholder': {
													color: "#FFFFFF",
												},
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'transparent',
													},
													'&:hover fieldset': {
														borderColor: 'transparent',
													},
													'&.Mui-focused fieldset': {
														borderColor: 'transparent',
													},
												},
											}}
										/>
									</div>
								</FormControl>
							</ClickAwayListener>
							}
					</IconButton>
				</Tooltip>
			</div>
		</div>
	)
};

function Chatbot(props) {
	const sendRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [conversationHistory, setConversationHistory] = useState([]);
	const [currentMessage, setCurrentMessage] = useState("");
	const [showSidebar, setShowSidebar] = useState(true);
	const [userResume, setUserResume] = useState(null);
	const [userLinkedIn, setUserLinkedIn] = useState("");
	const [jobPosting, setJobPosting] = useState("");
	const [loading, setLoading] = useState(false);

	// const api_request = () => {
	// 	const payload = {
	// 		CONVERSATION_HISTORY: JSON.stringify(conversationHistory),
	// 		USER_MESSAGE: "Please introduce yourself.",
	// 	}
	// 	axios.post(`${API_URL}/chatbot`, payload)
	// 	.then((res) => {
	// 		addSystemMessage(res.data.systemResponse);
	// 	})
	// 	.catch((err) => {
	// 		console.error('handleSendMessage - ERROR:', err);
	// 	});
	// };

	useEffect(() => {
		if (props.fullScreen && conversationHistory.length === 0) {
			const payload = {
				CONVERSATION_HISTORY: JSON.stringify(conversationHistory),
				USER_MESSAGE: "Please introduce yourself.",
			}
			axios.post(`${API_URL}/chatbot`, payload)
			.then((res) => {
				addSystemMessage(res.data.systemResponse);
			})
			.catch((err) => {
				console.error('handleSendMessage - ERROR:', err);
			});
		}
	});

	const handleToggleOpen = () => {
		const newOpen = !open;
		setOpen(newOpen);
		if (newOpen && conversationHistory.length === 0) {
			const payload = {
				CONVERSATION_HISTORY: JSON.stringify(conversationHistory),
				USER_MESSAGE: "Please introduce yourself.",
			}
			axios.post(`${API_URL}/chatbot`, payload)
			.then((res) => {
				addSystemMessage(res.data.systemResponse);
			})
			.catch((err) => {
				console.error('handleSendMessage - ERROR:', err);
			});
		}
	};

	const handleInputChange = (e) => {
		const newValue = sanitize(e?.target?.value || "", props.skipSanitize);
		setCurrentMessage(newValue);
	};

	const handleEnter = (e) => {
		if (e.key === "Enter") {
			if (e.shiftKey) {
				e.preventDefault();
				setCurrentMessage(currentMessage + "\n");
			} else {
				e.preventDefault();
				sendRef.current.click();
			}
		}
	};

	const addSystemMessage = (newMessage) => {
		if (newMessage.includes("~~~")) {
			const splitMessage = newMessage.split("~~~");
			const newMessages = [];
			splitMessage.forEach((m) => {
				if (m.trim() !== "") {
					newMessages.push({type: "system", message: m.trim()})
				}
			});
			setConversationHistory(m => ([...m, ...newMessages]));
		} else {
			setConversationHistory(m => ([...m, {type: "system", message: newMessage.trim()}]));
		}
	};

	const addUserMessage = (newMessage) => {
		setConversationHistory(m => ([...m, {type: "user", message: newMessage.trim()}]));
	};

	const handleSendMessage = () => {
		const currentConversationHistory = conversationHistory;
		const newMessage = currentMessage.trim();
		addUserMessage(currentMessage);
		setCurrentMessage("");
		const payload = {
			CONVERSATION_HISTORY: JSON.stringify(currentConversationHistory),
			USER_MESSAGE: newMessage,
			USER_RESUME: userResume,
			USER_LINKEDIN: userLinkedIn,
			JOB_POSITION_POSTING: jobPosting,
		}
		axios.post(`${API_URL}/chatbot`, payload)
		.then((res) => {
			addSystemMessage(res.data.systemResponse);
		})
		.catch((err) => {
			console.error('handleSendMessage - ERROR:', err);
		});
	};

	return (
		<div className={`${styles.chatbot} ${styles[props.fullScreen ? "chatbotFullScreen" : open ? "chatbotOpen" : "chatbotClosed"]}`}>
		
			{
				open || props.fullScreen ?
					<div className={styles.chatbotBox}>
						{showSidebar && <Sidebar setUserResume={setUserResume} setUserLinkedIn={setUserLinkedIn} setJobPosting={setJobPosting} />}
						<div className={styles.chatbotBody}>
							<div className={styles.chatbotMessagesContainer}>
								<div className={`${styles.chatbotMessages} ${styles[props.fullScreen ? "chatbotMessagesFullScreen" : "chatbotMessagesPopUp"]}`}>
									{conversationHistory.map((message, i) => {
										if (message.type === "system") {
											return <SystemMessage message={message.message} key={`system-message-${i}`} />
										} else {
											return <UserMessage message={message.message} key={`user-message-${i}`} />
										}
									})}
								</div>
							</div>
							<div className={styles.messageField}>
								<FormControl
									className={styles.messageFieldFormControl}
									fullWidth
								>
									<TextField
										className={styles.messageInput}
										type="text"
										sx={{
											'& .MuiInputBase-root': {
												padding: "14px",
											},
											'& .MuiInputBase-input': {
												color: "#FFFFFF",
											},
											'& .MuiInputBase-input::placeholder': {
												color: "#FFFFFF",
											},
											'& .MuiOutlinedInput-root': {
												'& fieldset': {
													borderColor: 'transparent', // Ensures no border when not focused
												},
												'&:hover fieldset': {
													borderColor: 'transparent', // Ensures no border on hover
												},
												'&.Mui-focused fieldset': {
													borderColor: 'transparent', // Removes the outline on focus
												},
											},
										}}
										placeholder="Please give Sensei a task..."
										minRows={1}
										maxRows={9}
										onChange={(e) => handleInputChange(e)}
										onKeyDown={(e) => handleEnter(e)}
										value={currentMessage}
										fullWidth
										multiline
										slotProps={{
											input: {
												endAdornment: (
													<InputAdornment position="end">
														<Button
															ref={sendRef}
															variant="contained"
															className={styles.sendMessageButton}
															onClick={(e) => handleSendMessage(e)}
														>
															<SendIcon />
														</Button>
													</InputAdornment>
												)
											}
										}}
									/>
								</FormControl>
							</div>
						</div>
					</div>
				:
					<></>
			}

			{!props.fullScreen &&
				<button
					className={styles.toggleButton}
					onClick={() => handleToggleOpen()}
				>
					<div className={`${styles.toggleButtonIcon} ${styles[open ? "toggleButtonIconOpen" : "toggleButtonIconClosed"]}`}>
						<AddIcon
							sx={{
								color: "#FFFFFF",
								padding: "0px",
								margin: "0px",
							}}
						/>
					</div>
				</button>}
		</div>
	)
}

export default Chatbot;