import React, { useState } from "react";
import './App.scss';
import {
  Pipeline,
} from "./pages";
import { MantineProvider } from '@mantine/core';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  BrowserRouter,
} from "react-router-dom";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1200,
      xl: 1600,
    },
  },
});

function App() {
  const [activeStepIndex, setActiveStepIndex] = useState(-1);
  const [activeStep, setActiveStep] = useState({});
	const [steps, setSteps] = useState([]);
	const [service, setService] = useState(null);
	const [data, setData] = useState({});
	const [output, setOutput] = useState("");
	const [userResume, setUserResume] = useState("");
	const [userLinkedInProfile, setUserLinkedInProfile] = useState("");
	const [userInformation, setUserInformation] = useState("");
	const [jobPositionPosting, setJobPositionPosting] = useState("");
	const [messageRecipientInformation, setMessageRecipientInformation] = useState("");
	const [questions, setQuestions] = useState("");
	const [connections, setConnections] = useState("");
	const [furtherContext, setFurtherContext] = useState("");
	const [loading, setLoading] = useState(false);
	const [company, setCompany] = useState("");
	const [jobTitle, setJobTitle] = useState("");
  const [userName, setUserName] = useState("");
  const [professionalTitle, setProfessionalTitle] = useState("");
	const [disableNext, setDisableNext] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [processUploadCalls, setProcessUploadCalls] = useState([]);
  const [placeholdersUsed, setPlaceholdersUsed] = useState(false);

  const resetState = () => {
    setActiveStep({});
    setSteps([]);
    setService(null);
    setData({});
    setOutput("");
    setUserResume("");
    setUserLinkedInProfile("");
    setUserInformation("");
    setJobPositionPosting("");
    setMessageRecipientInformation("");
    setQuestions("");
    setConnections("");
    setFurtherContext("");
    setLoading(false);
    setCompany("");
    setJobTitle("");
    setUserName("");
    setProfessionalTitle("");
    setDisableNext(false);
    setCompletedSteps([]);
    setProcessUploadCalls([]);
    setPlaceholdersUsed(false);
  };
  
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <MantineProvider>
          <BrowserRouter>
            <Pipeline
              resetState={resetState}
              activeStepIndex={activeStepIndex}
              activeStep={activeStep}
              steps={steps}
              service={service}
              data={data}
              output={output}
              userResume={userResume}
              userLinkedInProfile={userLinkedInProfile}
              userInformation={userInformation}
              jobPositionPosting={jobPositionPosting}
              messageRecipientInformation={messageRecipientInformation}
              questions={questions}
              connections={connections}
              furtherContext={furtherContext}
              loading={loading}
              company={company}
              jobTitle={jobTitle}
              userName={userName}
              professionalTitle={professionalTitle}
              disableNext={disableNext}
              completedSteps={completedSteps}
              processUploadCalls={processUploadCalls}
              placeholdersUsed={placeholdersUsed}
              setActiveStepIndex={setActiveStepIndex}
              setActiveStep={setActiveStep}
              setSteps={setSteps}
              setService={setService}
              setData={setData}
              setOutput={setOutput}
              setUserResume={setUserResume}
              setUserLinkedInProfile={setUserLinkedInProfile}
              setUserInformation={setUserInformation}
              setJobPositionPosting={setJobPositionPosting}
              setMessageRecipientInformation={setMessageRecipientInformation}
              setQuestions={setQuestions}
              setConnections={setConnections}
              setFurtherContext={setFurtherContext}
              setLoading={setLoading}
              setCompany={setCompany}
              setJobTitle={setJobTitle}
              setUserName={setUserName}
              setProfessionalTitle={setProfessionalTitle}
              setDisableNext={setDisableNext}
              setCompletedSteps={setCompletedSteps}
              setProcessUploadCalls={setProcessUploadCalls}
              setPlaceholdersUsed={setPlaceholdersUsed}
            />
          </BrowserRouter>
        </MantineProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
