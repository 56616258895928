import React, { useState } from "react";
import styles from "./Generate.module.scss";
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import downloadPDF from "../../util/downloadPDF";
import {
	TextArea,
	ServiceWrapper,
} from "../../components";


function Generate({ output, onChange = null, ...props}) {
	const [disabled, setDisabled] = useState(false);
	const [downloadDisabled, setDownloadDisabled] = useState(false);
	const [downloading, setDownloading] = useState(false);

	const handleChange = (e) => {
		if (onChange) {
			onChange(e.target.value);
			if (downloadDisabled) {
				setDownloadDisabled(false);
			}
		}
	}

	const handleGenerate = () => {
		setDisabled(true);
		props.regenerate();
		setTimeout(() => {
			setDisabled(false);
			if (downloadDisabled) {
				setDownloadDisabled(false);
			}
		}, 3000);
	}

	const handleDownload = () => {
		setDownloadDisabled(true);
		setDownloading(true);
		if (props.service === "BUILD_RESUME" || props.service === "IMPROVE_RESUME") {
			downloadPDF(output, `${props.userName.replace(/\s/g,'_')}-${props.professionalTitle.replace(/\s/g,'_')}-Resume`);
		} else if (props.service === "TUNE_RESUME") {
			downloadPDF(output, `${props.company.replace(/\s/g,'_')}-${props.jobTitle.replace(/\s/g,'_')}-Resume`);
		} else {
			downloadPDF(output, `${props.company.replace(/\s/g,'_')}-${props.jobTitle.replace(/\s/g,'_')}`);
		}
		setDownloading(false);
	}

	return (
		<ServiceWrapper {...props}>
			<div className={styles.generate}>
				<div className={styles.text}>
					<h2 className={styles.title}>{props.activeStep.title}</h2>
				</div>
				<Typography className={styles.disclaimer} variant="caption">AI can make mistakes. Always review what it creates for accuracy.</Typography>
				<div className={styles.output}>
					<div className={styles.outputButtons}>
						<LoadingButton
							variant="contained"
							loading={props.loading || disabled}
							startIcon={<AutorenewIcon />}
							onClick={() => handleGenerate()}
							disabled={props.loading || disabled}
						>
							Regenerate
						</LoadingButton>
						{props.download &&
							<LoadingButton
								variant="contained"
								loading={downloading || props.loading || disabled}
								startIcon={<FileDownloadIcon />}
								onClick={() => handleDownload()}
								disabled={downloadDisabled || downloading || props.loading || disabled}
							>
								Download PDF
							</LoadingButton>
						}
					</div>
					{
						props.activeStep.descriptiveText && 
						<p className={styles.descriptiveText}>
							The displayed text is in an edit-friendly format.  The downloaded PDF will be formatted for professional use.
						</p>
					}
					<TextArea
						usePropValue
						value={output}
						onChange={(e) => handleChange(e)}
						minRows={15}
						style={{
							xs: { width: "100%" },
							sm: { width: "100%" },
							md: { width: "50%" },
						}}
					/>
				</div>
				<Typography className={styles.helperText} variant="caption">{props.activeStep.helperText}</Typography>
			</div>
		</ServiceWrapper>
	)
}

export default Generate;