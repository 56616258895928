// export const API_URL = 'http://localhost:9000';
// export const SOCKET_URL = 'ws://localhost:9000';

// export const API_URL = 'https://api.applysamurai.com';
// export const SOCKET_URL = 'ws://api.applysamurai.com';

export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const MIN_OUTPUT_ROWS_EXPORT = 20;
export const OUTPUT_ROWS_EXPORT = 35;
export const MAX_OUTPUT_ROWS_EXPORT = 35;

export const MIN_OUTPUT_ROWS = 20;
export const OUTPUT_ROWS = 40;
export const MAX_OUTPUT_ROWS = 40;

export const RECOMMENDED = [
  "USER_RESUME",
  "USER_LINKEDIN_PROFILE",
];

export const BG1 = "#003366";
export const BG2 = "#4a90e2";